/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
@tailwind preflight;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 */

* {
    box-sizing: border-box;
}

a {
    @apply .text-blue .no-underline;
}

.header {
    background-image: url('/assets/hero.svg');
    background-attachment: fixed;
    background-position: center;
}

.typed-text:after {
    @apply .h-full .bg-blue .block .absolute;
    width: 1px;
    content: "";
    top: 1px;
    right: -.3rem;
    animation: caret 1s steps(1) infinite;
}

@keyframes caret {
    50% {
        opacity: 0;
    }
}

.container {
    width: 100%;
}

@media (min-width: 576px) {
    .container {
        max-width: 576px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

@media (min-width: 1000px) {
    .container {
        max-width: 1000px;
    }
}

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 */
@tailwind utilities;

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 */
